<ngx-spinner [name]="pageLevelSpinner" *ngIf="!componentLoaded" bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="line-spin-fade" [fullScreen]="true">
	<p style="color: white">Loading...</p>
</ngx-spinner>
<app-confirmation-popup />

<div *ngIf="componentLoaded" class="main-container">
	<nav class="sidebar" [ngClass]="sidebarExpanded ? 'expanded' : 'collapsed'">
		<header>
			<div class="image-text">
				<a class="image" [routerLink]="['']">
					<img src="../../../../assets/images/Gold Star_2500px.png" height="50px" />
				</a>

				<a class="text header-text" [ngClass]="sidebarExpanded ? 'header-text-visible' : 'header-text-collapsed'" [routerLink]="['']">
					<span class="name"><span class="brand-text-medium">TRB</span></span>
				</a>

				<div class="arrow-left" [ngClass]="sidebarExpanded ? 'flex-container' : 'hidden'" (click)="toggleSideNav()">
					<i class="bx bx-chevrons-left bx-xs toggle"></i>
				</div>
			</div>
		</header>
		<ngx-spinner [name]="menuContainerSpinner" *ngIf="!menuLoaded" size="medium" color="#fff" type="square-jelly-box" bdColor="rgba(255,255,255,0.8);" [fullScreen]="false">
			<div id="wrapper">
				<div class="gears">
					<div class="gears-container">
						<div class="gear-rotate"></div>
						<div class="loading-text-sec">
							<span class="loading-text" style="left: 43%">Loading...</span>
						</div>
					</div>
				</div>
			</div>
		</ngx-spinner>

		<div *ngIf="menuLoaded" class="search-placeholder" (click)="searchPlaceHolderClick($event)">
			<input placeholder="Search..." class="search-box" [ngClass]="sidebarExpanded ? 'expanded-search-box' : 'collapsed-search-box'" [(ngModel)]="searchTerm" (keyup)="searchKeyUp($event)" />
			<span class="search-icon">
				<i *ngIf="!searchInProgress" class="bx bx-search bx-xs"></i>
				<i *ngIf="searchInProgress" class="bx bx-x bx-xs" (click)="clearSearch()"></i>
			</span>
		</div>

		<!--MENU CONTENT CONTAINER-->
		<div class="menu-bar flex-container">
			<!-- <img src="../../../../assets/newlayout/Loader/Cube-1s-200px.gif"> -->
			<ng-container *ngIf="menuLoaded">
				<div class="menu sidebar-menu-height" [ngClass]="sidebarExpanded ? 'sidebar-menu-border' : 'sidebar-menu-no-border'">
					<ng-scrollbar class="scrollbar-height">
						<ul class="menu-links">
							<li
								id="{{ elementIdPrefix + 'menuGroup-' + menuGroupModel.name.split(' ').join('') }}"
								class="nav-link"
								*ngFor="let menuGroupModel of menuGroupModelList"
								(click)="onMenuItemSelected(menuGroupModelList, menuGroupModel)"
							>
								<a class="icon-link" (click)="onMenuGroupSelected(menuGroupModel)">
									<div [ngClass]="menuGroupModel.selected ? 'icon-active' : 'icon-not-active'">
										<i class="bx bx-sm icon" [ngClass]="menuGroupModel.iconName"></i>
									</div>
									<span class="text nav-base-text" [ngClass]="menuGroupModel?.selected ? 'nav-text-selected' : 'nav-text'">{{ menuGroupModel.name }}</span>
								</a>
							</li>
						</ul>
					</ng-scrollbar>
				</div>

				<!-- RECURSIVE TEMPLATE FOR TREE VIEW 
					(https://stackoverflow.com/questions/63795040/a-recursive-function-in-angular-html-document) 
				-->
				<ul class="nav-item-container" [ngClass]="sidebarExpanded ? 'visible' : 'hidden'">
					<ng-container *ngFor="let menuModel of selectedMenuGroupModel?.items" [ngTemplateOutlet]="treeNode" [ngTemplateOutletContext]="{ $implicit: menuModel }"> </ng-container>
				</ul>

				<ng-template #treeNode let-data>
					<ul>
						<li
							*ngIf="!data.nestedChild"
							id="{{ elementIdPrefix + 'childMenu-' + selectedMenuGroupModel?.name?.split(' ')?.join('') + '-' + data.name.split(' ').join('') }}"
							class="nav-item-list"
						>
							<!-- FOR MENU WITH EXTERNAL LINK -->

							<!-- BOOKMARK ICON-->
							<i
								*ngIf="data?.menuItem?.isFavorite"
								class="bx bx-xs icon bxs-star bookmark-star-favorite"
								[ngClass]="data?.name?.split(' ').length > 2 ? 'margin-double-word' : 'margin-word'"
								(click)="markMenuAsFavorite(data)"
							></i>
							<i
								*ngIf="!data?.menuItem?.isFavorite"
								class="bx bx-xs icon bx-star bookmark-star"
								[ngClass]="data?.name?.split(' ').length > 2 ? 'margin-double-word' : 'margin-word'"
								(click)="markMenuAsFavorite(data)"
							></i>

							<a *ngIf="data.isExternalLink" class="nav-item-link" target="_blank" [href]="data.externalLinkURL">
								<span
									class="nav-item"
									[ngxTippy]="toolTipTemplate"
									[tippyProps]="{ placement: 'right', arrow: data.menuItem.description && data.menuItem.description.trim().length > 0 }"
									>{{ data.name }}</span
								>
								<i *ngIf="data.items.length > 0 && !data.childVisible" class="bx bxs-chevron-right bx-xs expand-left" (click)="showImmediateChild(data)"></i>
								<i *ngIf="data.childVisible" class="bx bxs-chevron-down bx-xs expand-down" (click)="hideImmediateChild(data)"></i>
							</a>

							<!-- FOR MENU WITHOUT EXTERNAL LINK -->
							<a *ngIf="!data.isExternalLink" class="nav-item-link" [routerLink]="['', data.urlGUID]">
								<span
									class="nav-item"
									[ngxTippy]="toolTipTemplate"
									[tippyProps]="{ placement: 'right', arrow: data.menuItem.description && data.menuItem.description.trim().length > 0 }"
									>{{ data.name }}</span
								>
								<i *ngIf="data.items.length > 0 && !data.childVisible" class="bx bxs-chevron-right bx-xs expand-left" (click)="showImmediateChild(data)"></i>
								<i *ngIf="data.childVisible" class="bx bxs-chevron-down bx-xs expand-down" (click)="hideImmediateChild(data)"></i>
							</a>
							<div #toolTipTemplate class="menu-tooltip" [hidden]="!data.menuItem.description || data.menuItem.description.trim().length === 0">
								{{ data.menuItem.description }}
							</div>
						</li>
						<li id="{{ elementIdPrefix + 'childMenu-' + data.name.split(' ').join('') }}" class="nested-child-list" *ngIf="data.nestedChild" tabindex="-1" disabled>
							<a class="nested-child-container">
								<i class="bx bx-radio-circle bx-xs nested-menu-icon"></i>
								<span class="nested-menu-title" tabindex="-1" disabled>{{ data.name }}</span>
							</a>
						</li>
						<ng-container *ngIf="data.items">
							<ul *ngIf="data.childVisible" class="nested-child-ul">
								<ng-container *ngFor="let childItems of data.items" [ngTemplateOutlet]="treeNode" [ngTemplateOutletContext]="{ $implicit: childItems }"></ng-container>
							</ul>
						</ng-container>
					</ul>
				</ng-template>
			</ng-container>
		</div>
	</nav>
	<app-content-container [ngStyle]="mainContentContainerStyle" [sideBarExpanded]="sidebarExpanded" (onTogglerClick)="toggleSideNav()"></app-content-container>
</div>
