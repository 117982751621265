import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Breadcrumb } from '../../models/model';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../models/classes';
import { breadCrumbMenuFavoriteState, breadcrumbIconsActionState, breadcrumbState, favoriteIconOnBreadcrumbState } from '../../../../store/store.selector';
import { MenuService } from '../../../../../app/services/menuService';
import { MenuGroupModel, MenuItemModel } from '../../../../../app/models/models';
import { WikiManualService } from '../../../../modules/components/stand-alone/resource-center/services/wiki-manual.service';
import { PermissionService } from '../../../../../../../goldstar-share/src/app/services/permission.service';
import { ErrorHandlingService } from '../../../../../../../goldstar-share/src/app/services/error-handling.service';
import { FavoriteMenuUpdateAction, MenuLoadedStateAction } from '../../../../store/store.actions';
import { cloneDeep, differenceBy } from 'lodash';
import { CollectionHelper } from '../../../../../../../goldstar-share/src/app/services/collection.helper';
import { ToDoService } from '../../../../modules/services/todo.service';

@Component({
	selector: 'app-breadcrumb',
	templateUrl: './breadcrumb.component.html',
	styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit {
	breadcrumb: Breadcrumb[] = [];
	@Input() bgColor = '#eee';
	@Input() fontSize = '18px';
	@Input() fontColor = '#0275d8';
	@Input() lastLinkColor = '#000';
	@Input() symbol = ' / ';
	params!: { [key: string]: any };

	recentMenu!: MenuGroupModel;
	isFavorite: boolean = false;
	showEditIcon: boolean = false;
	private sideBarItemSub!: Subscription;
	showFavoriteIcon: boolean = true;
	showEyeIcon: boolean = false;
	resourceCenterComponentName: string = 'Resource Center';
	showShareSection: boolean = false;
	@ViewChild('shareIcon') shareIcon!: ElementRef;
	@ViewChild('shareModal') shareModal!: ElementRef;
	@Output() showHideSpinner: EventEmitter<boolean> = new EventEmitter();

	constructor(
		private store: Store<AppState>,
		public menuService: MenuService,
		public wikiManualService: WikiManualService,
		public permissionService: PermissionService,
		public errorHandlingService: ErrorHandlingService,
		public todoService: ToDoService,
		public elementRef: ElementRef
	) {
		this.store.select(breadcrumbState).subscribe(async (y) => {
			this.breadcrumb = y;
			this.isFavorite = this.breadcrumb[this.breadcrumb.length - 1]?.menuItemModel?.isFavorite ?? false;
		});

		this.store.select(breadCrumbMenuFavoriteState).subscribe(async (y) => {
			if (y.length > 0) {
				const isLoadedBreadcrumb = differenceBy(this.breadcrumb, y, (x) => x.label);
				if (isLoadedBreadcrumb.length <= 0) {
					this.breadcrumb = y;
					this.isFavorite = this.breadcrumb[this.breadcrumb.length - 1].menuItemModel?.isFavorite ?? false;
				}
			}
		});

		this.store.select(favoriteIconOnBreadcrumbState).subscribe((showIcon) => {
			this.showFavoriteIcon = showIcon;
		});

		this.store.select(breadcrumbIconsActionState).subscribe((showIcon) => {
			this.showEditIcon = showIcon?.showEditIconOnBreadcrumb ?? false;
			this.showEyeIcon = showIcon?.showEyeIconOnBreadcrumb ?? false;
		});
		this.handleOutsideClick = this.handleOutsideClick.bind(this);
	}
	handleOutsideClick(event: Event) {
		setTimeout(() => {
			if (!this.shareIcon.nativeElement.contains(event.target) && this.showShareSection && !this.shareModal.nativeElement.contains(event.target)) {
				this.closeShare();
			}
		}, 100);
	}
	ngOnInit(): void {
		this.sideBarItemSub = this.wikiManualService.onSidebarItemSelected.subscribe((selectedBook) => {
			if (selectedBook && this.wikiManualService.selectedSideBarItem) {
				this.wikiManualService.updateBreadCrumbList();
			}
		});
	}
	ngOnDestroy(): void {
		if (this.sideBarItemSub) this.sideBarItemSub.unsubscribe();

		document.removeEventListener('click', this.handleOutsideClick);
	}

	onShareClick() {
		this.showShareSection = !this.showShareSection;
		if (this.showShareSection) {
			setTimeout(() => {
				document.addEventListener('click', this.handleOutsideClick);
			});
		} else {
			// Remove event listener when the modal is closed
			document.removeEventListener('click', this.handleOutsideClick);
		}
	}

	closeShare() {
		this.showShareSection = false; // Remove event listener when the modal is closed
		document.removeEventListener('click', this.handleOutsideClick);
	}

	openMail() {
		navigator.clipboard
			.writeText(window.location.href)
			.then(() => {
				// If successful, create a mailto link and navigate to it
				const mailtoLink = `mailto:?subject=Subject&body=${encodeURIComponent(window.location.href)}`;
				// Check if the browser supports the 'navigator.share' API (modern browsers)
				if (navigator.share) {
					// Use navigator.share for a better user experience
					navigator
						.share({
							title: 'Subject',
							text: window.location.href,
						})
						.catch((error) => {
							// Handle any errors that occurred during sharing
							console.error('Error sharing:', error);
							// If sharing fails, fallback to opening a mailto link
							window.location.href = mailtoLink;
							this.showShareSection = false;
						});
				} else {
					// Fallback for browsers that do not support navigator.share
					window.location.href = mailtoLink;
					this.showShareSection = false;
				}
			})
			.catch((err) => {
				// Handle any errors that occurred during clipboard access
				console.error('Error copying to clipboard:', err);
			});
		// navigator.clipboard.writeText(window.location.href);
		// const mailtoLink = `mailto:?subject=Subject&body=${encodeURIComponent(window.location.href)}`;
		// window.location.href = mailtoLink;
	}
	openCopyLink() {
		navigator.clipboard
			.writeText(window.location.href)
			.then(() => {
				this.errorHandlingService.showSuccessMessage('Copied to clipboard!', false);
				this.showShareSection = false;
			})
			.catch((err) => {
				// Handle any errors that occurred during clipboard access
				console.error('Error copying to clipboard:', err);
			});
		// navigator.clipboard.writeText(window.location.href);
		// this.errorHandlingService.showSuccessMessage('Copied to clipboard!', false);
		// this.showShareSection = false;
	}

	openTeams() {
		navigator.clipboard
			.writeText(window.location.href)
			.then(() => {
				const userEmail = '';
				const dataToShare = window.location.href;
				const shareableLink = `https://teams.microsoft.com/l/chat/0/0?users=${encodeURIComponent(userEmail)}&message=${encodeURIComponent(dataToShare)}`;
				window.open(shareableLink, '_blank');
				this.showShareSection = false;
			})
			.catch((err) => {
				// Handle any errors that occurred during clipboard access
				console.error('Error copying to clipboard:', err);
			});
		//navigator.clipboard.writeText(window.location.href);
	}

	async OnFavoriteClick() {
		this.showHideSpinner.emit(true);
		let menuItem = this.breadcrumb[this.breadcrumb.length - 1].menuItemModel!;
		menuItem = { ...menuItem, isFavorite: !this.isFavorite };
		if (menuItem.isFavorite) {
			this.menuService.addUserFavoriteMenu(menuItem, [
				() => {
					// updating wiki favorite item
					if (menuItem.isWikiFavorite) {
						this.wikiManualService.updateWiKiFavoriteModule(menuItem.menuGUID ?? '', menuItem.isFavorite ?? false, menuItem.favoriteOrder ?? 0);
					}
					const clonedFavoriteMenus = cloneDeep(this.menuService.favoriteMenuCollection);
					this.store.dispatch(FavoriteMenuUpdateAction({ menuList: clonedFavoriteMenus }));
				},
				() => {
					// push the menu loaded action
					this.store.dispatch(MenuLoadedStateAction({ menuLoaded: false }));
					// push the menu loaded action
					this.store.dispatch(MenuLoadedStateAction({ menuLoaded: true }));
					this.isFavorite = !this.isFavorite;
				},
			]);
		} else {
			this.menuService.menuGroupCollection.forEach((menuGroup: MenuGroupModel) => {
				menuGroup.menuItems.forEach((menuItemModel: MenuItemModel) => {
					if (menuItemModel.menuGUID === menuItem.menuGUID) {
						menuItemModel.isFavorite = menuItem.isFavorite;
					}
				});
			});

			// Refresh the favorite menus
			const clonedFavoriteMenus = cloneDeep(this.menuService.favoriteMenuCollection);
			this.store.dispatch(FavoriteMenuUpdateAction({ menuList: clonedFavoriteMenus }));

			const allMenuList = CollectionHelper.selectMany(this.menuService.menuGroupCollection, (x) => x.menuItems);
			let nonWikiFavItems = allMenuList.filter((x) => !x.isWikiFavorite);
			this.menuService.updateFavoriteMenuRemote(nonWikiFavItems);
			// updating wiki favorite item
			if (menuItem.isWikiFavorite) {
				await this.menuService.updateUserWikiFavoriteMenu(allMenuList);
				this.wikiManualService.updateWiKiFavoriteModule(menuItem.menuGUID ?? '', menuItem.isFavorite ?? false, menuItem.favoriteOrder ?? 0);
			}
			this.isFavorite = !this.isFavorite;
			// push the menu loaded action
			this.store.dispatch(MenuLoadedStateAction({ menuLoaded: true }));
		}
		this.showHideSpinner.emit(false);
	}

	async editIconClicked() {}

	async eyeIconClicked() {
		this.todoService.showAuditDetailsModal({
			showToDoAuditDetailsModal: true,
			todoItem: this.todoService.selectedToDoItem,
		});
	}
}
